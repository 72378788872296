import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components'

export default class ErrorBoundary extends Component {
  constructor(props) {
      super(props);
      this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
      if (this.state.error) {
        return (
            <Container>
              <p style={{fontSize: '20px'}}>This is our bad...</p> <br />
              <div style={{fontSize: '14px'}}>It seems something broke on our end - sorry about that. It's still early days for us, so you may run into small bugs here and there. 
               <br />
              If you could let us know what happened by dropping a quick email: <a style={{cursor: 'pointer'}}href='mailto:Brennon@NewTheory.io'>Brennon@NewTheory.io</a>, it'll help us fix this bug right away. </div>
            </Container>
          );
      } else {
          //when there's not an error, render children untouched
          return this.props.children;
      }
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 800px;
  justify-content: center;
  align-items: center;
`
