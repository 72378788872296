import React, {useState} from 'react'

export const UserProvider = React.createContext();

function UserContext (props) {

  const [user, setUser] = useState([])
  
  return (
    <UserProvider.Provider value={[user, setUser]}>
      {props.children}
    </UserProvider.Provider>
  )
}

export default UserContext