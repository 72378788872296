import React from 'react';
import { hydrate, render } from "react-dom";
import Website from './Website.js'
import { Provider } from 'mobx-react'
import mainStore from './stores/MainStore.js'
import * as Sentry from '@sentry/browser';
import './index.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css'
const store = new mainStore()



if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://3e2532f602a346a4b5d37026058b1f84@sentry.io/1316758"
  });
}



const Root = (
  <Provider store={store}>
    <Website store={store} />
  </Provider>
)
 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(Root, rootElement);
} else {
  render(Root, rootElement);
}