import React, { Suspense, lazy, useEffect } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import createBrowserHistory from 'history/createBrowserHistory';
import ErrorBoundary from './components/General/Sentry'
import 'antd/dist/antd.css'
//* -------------------------------------------
//* leave firebaseInitialize for creating new accounts!
// import { firebaseInitialize } from './initialize/config.js' //This MUST stay here to initialize App
// import { Signup } from './Website/Signup.js'
//* -------------------------------------------
// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import UserProvider from './Providers/User-Provider'
const Home = lazy(() => import('./Website/Home.js'));
const About = lazy(() => import('./Website/About.js'));
const Services = lazy(() => import('./Website/Services.js'));
const WinLoss = lazy(() => import('./Website/Win-Loss.js'));
const App = lazy(() => import('./App.js'));
const Login = lazy(() => import('./Website/Login.js'));
const Careers = lazy(() => import('./Website/Careers.js'));
const Privacy = lazy(() => import('./Website/Privacy-Policy.js'));
const HighGrowth = lazy(() => import('./Website/High-Growth.js'));
const InterviewGuide = lazy(() => import('./Website/Interview-Guide.js'));
const Technology = lazy(() => import('./Website/Technology.js'));
const Gdpr = lazy(() => import('./Website/Gdpr-compliance.js'));
const Ccpa = lazy(() => import('./Website/Ccpa-compliance.js'));
const CustomerAcquisition = lazy(() => import('./Website/Customer-Acquisition.js'));
const CustomerRetention = lazy(() => import('./Website/Customer-Retention.js'));
const CustomerValue = lazy(() => import('./Website/Customer-Value.js'));
const ResetPassword = lazy(() => import('./Website/Reset-Password'));
const Webinar = lazy(() => import('./Website/Webinar.js'));
const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routingStore);

const theFullHref = window.location.href
const urlPart = theFullHref.split("/");
const theDomain = urlPart[2]


function Website (props) {

  useEffect(() => {
    console.log(theDomain === 'new-theory-staging.web.app')
    if (theDomain !== 'new-theory-staging.web.app') {
      ReactGA.initialize('UA-131569795-01');
      ReactGA.send("pageview")
      //ReactGA.pageview(window.location.pathname + window.location.search);
    }
  },[])
  
    return (  
      <UserProvider>
        <ErrorBoundary>
            <Router history={history} store={props.store}>
              <Suspense fallback={<div style={{color: 'white'}}>Loading...</div>}>
                <Switch>
                  <Route public={true} store={props.store} exact path="/" component={Home} history={history} />
                  <Route public={true} store={props.store} exact path="/about" component={About} history={history}/>
                  <Route public={true} store={props.store} exact path="/gdpr-compliance" component={Gdpr} history={history}/> 
                  <Route public={true} store={props.store} exact path="/ccpa-compliance" component={Ccpa} history={history}/>  
                  <Route public={true} store={props.store} exact path="/privacy-policy" component={Privacy} history={history}/>  
                  <Route public={true} store={props.store} exact path="/technology" component={Technology} history={history}/>
                  <Route public={true} store={props.store} exact path="/products/win-loss-analysis" component={WinLoss} history={history}/>
                  <Route public={true} store={props.store} exact path="/products/churn-analysis" component={Services} history={history}/>
                  <Route public={true} store={props.store} exact path="/services/customer-acquisition" component={CustomerAcquisition} history={history}/>
                  <Route public={true} store={props.store} exact path="/services/customer-retention" component={CustomerRetention} history={history}/>
                  <Route public={true} store={props.store} exact path="/services/customer-value" component={CustomerValue} history={history}/>
                  <Route public={true} store={props.store} exact path="/guides/high-growth-guide" component={HighGrowth} history={history}/>  
                  <Route public={true} store={props.store} exact path="/guides/interview-guide" component={InterviewGuide} history={history}/>  
                  <Route public={true} store={props.store} exact path="/careers" component={Careers} history={history}/>  
                  <Route public={true} store={props.store} exact path="/webinars/product-led-growth" component={Webinar} history={history}/>  
                  {/* //* leave Signup here for creating new accounts! */}
                  {/* <Route public={true} store={props.store} exact path="/signup" component={Signup} history={history}/> */}
                  
                  {/* <Route public={false} path="/services/product-market-fit" render={(match) => <ProductMarketFit history={history}/> } /> */}

                  <Route public={true} exact path="/reset-password" render={(match) => <ResetPassword match={match} history={history} />} />
                  <Route public={true} exact path='/login' render={(match) => <Login match={match} store={props.store} history={history} />} /> 
                  <Route public={false} path="/:sprint/:key" render={(match) => <App match={match} store={props.store} history={history}/> } />
                </Switch>
              </Suspense>
            </Router>   
        </ErrorBoundary>
      </UserProvider>
    );
  }




export default Website
