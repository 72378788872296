import { observable, decorate } from 'mobx'

class store {
    organization = ''
    user = {}
    currentSelection = ''
    userId = ''
    zoomSignupEmail = ''
    zoomInterviewLink = 'loading zoom link...'
    currentUser = {}
    transcriptChildren = []
    highlights = []
    highlightsForTranscript = []
    userEmail = ''
    showWelcomeObjects = false
    allMoments = []
    loggingIn = true
    signingUp = true
    allInterviews = {}
    currentlyLoadingSprint = ''
    authed = false
    admin = ''
    popOverText = ''
    popOverTop
    currentHoverMark
    currentHoverMarkId = ""
    popOverIsOpen = false
    togglePopOver = false
    popOverLeft
    currentHighlight
    popOverAlreadyOpen = false
    popOverDisplay = 'none'
    userJustSelected = false
    zoomAccountId = ''
    params = []
    tagColorClickedOn
    currentTranscript = []
    modalItem = {}
    allMomentsAllSprints2 = {}
    attachPersonas = {}
    navigatingFrom = ''
    currentSprint = ''
    haveShownSafariWarning = false
    currentKey = ''
    toggle = true
    toggle2 = true
    everybody = []
    getStartOfSelected = {}
    everybodyAllSprints = []
    everybodyCleaned = []
    everybodyCleanedSorted = []
    questions =  []
    firstDrag = 0
    algoliaPush = []
    questionsCleaned = []
    personas = {} //persona grab from db
    personasCleaned = [] // personas for display on interviews
    personaFilter =  {} //filter for top of tabs-moments page
    cleanedMomentsWithPersonas = []
    hasClickedOnTranscriptInfo = {}
    lastSprintVisited = ''
    sentimentFilter = {
      needs:true,
      positive:true,
      negative:true,
      misunderstanding:true,
      ideas:true,
      interesting:true, 
    }
    allMomentsFilter = true
    fullFilter = []
    newFilter = []
    allTags = []
    previousURL = ''
    insightFilter1 = []
    insightFilter2 = []
    insightFilter3 = []
    insightFilter4 = []
    needs = []   
    positive = []
    negative = []
    currentInterview = {}
    searchResultsShowing = false 
    currentInsightModalKey = ''
    misunderstanding = []
    interviewMoments = []
    interviewMomentsCleaned = []
    ideas = []
    interesting = []
    pileData = {}
    navigateDirect = true
    currentPile = {}
    allInsights = {}
    allInsightsJustThisSprint = []
    allInsightsCleaned = []
    highlightHeight
    highlightWidth
    allMomentsIsLoading = true
    currentSprintForMoments = ''
    allMomentsAllSprints = {}
    allMomentsAllSprintsLoaded = false
    interviewMomentKey = ''
    currentSprint = ''
    allMomentsThisSprint = []
    insightNames = []
    allInsightsWithKey = []
    insight = {}
    insights = {
      children: [
        {
          "insight": "initial State",
          "size": 0,
        },
      ],
    }
    insightsThisSprint= {
      children: [
        {
          "insight": "initial State",
          "size": 0,
        },
      ],
    }
    showNotesAll = false
    cleanedMoments =  []
    sprintName =  ''
    exitSurvey = {}
    highlightableRefs = []
    sprintTimeline =  ''
    navigation =  []
    modalComments = []
    popOverVisible = false
    weAreAtTop = false
    pilesNav = {}
    mouseIsOnPopOverNow = false
    AdditionalFeedback = []
    momentsSupportingInsight = []
    questionsFeedback = []
    photoURL = ''
    first_name = ''
    last_name = ''
    getStartSelectedId
    increasePileIconSize = false
    signupPassword = ''
    showTabsbyDefault = 0
    thisSprintChecked = true
    allSprintsChecked = false
    slackCredentials = []
    slackWebhook =  ''
    currentSelectionBugCatch
    showPopOver = false
    thisSprintBubble = false
    allSprintsBubble = false
    tagsFilter = []
    interviewMomentDeleteKey = ''
    videoScrubber = []
    haveCurrentPaymentMethod = false
    // YouTubePlayerState = -1
    videoStartsAt = 0
    stopsAt = 0
    weAreDragging = false
    changeCardUponDragTemp = false
    playButton = false
    infiniteScrollStart
    infiniteScrollStop = 20
    // get startPlayingVideo(){
    //   this.YouTubePlayerState === 1 ? this.youTubePlayerObject.seekTo(this.videoStartsAt) : 0        
    // }
   tagColors = ['rgba(255,44,64,100)','rgba(253,62,141,100)','rgba(187,0,189,100)','rgba(138,63,196,100)','rgba(97,97,194,100)', 
    'rgba(40,160,251,100)','rgba(27,195,253,100)','rgba(0,181,248,100)','rgba(0,203,221,100)','rgba(0,171,156,100)','rgba(59,196,106,100)','rgba(138,213,102,100)','rgba(204,232,76,100)',
    'rgba(238,229,68,100)','rgba(255,164,0,100)','rgba(255,92,42,100)','rgba(145,108,98,100)','rgba(189,189,189,100)','rgba(140,165,174,100)']
    bubbleData = {
      labels: ['January'],
      datasets: [
        {
          label: 'All Insights',
          fill: false,
          lineTension: 0.0,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: []
        }
      ]
    }
}

  decorate(store, {
    authed: observable,
    organization: observable,
    user: observable,
    haveCurrentPaymentMethod: observable,
    photoURL: observable,
    popOverAlreadyOpen: observable,
    allMomentsAllSprints2: observable,
    userJustSelected: observable,
    userId: observable,
    getStartSelectedId: observable,
    allTags: observable,
    getStartOfSelected: observable,
    highlightableRefs: observable,
    highlights: observable,
    highlightsForTranscript: observable,
    signingUp: observable,
    currentSprint: observable,
    transcriptChildren: observable,
    currentHoverMark: observable,
    currentHoverMarkId: observable,
    currentSelectionBugCatch: observable,
    hasClickedOnTranscriptInfo: observable,
    popOverIsOpen: observable,
    highlightHeight: observable,
    highlightWidth: observable,
    userEmail: observable,
    tagsFilter: observable,
    pilesNav: observable,
    currentlyLoadingSprint: observable,
    previousURL: observable,
    navigateDirect: observable,
    weAreAtTop: observable,
    tagColorClickedOn: observable,
    popOverText: observable,
    popOverTop: observable,
    popOverLeft: observable,
    popOverDisplay: observable,
    weAreDragging: observable,
    firstDrag: observable,
    signupPassword: observable,
    haveShownSafariWarning: observable,
    showWelcomeObjects: observable,
    exitSurvey: observable,
    insightNames: observable,
    lastSprintVisited: observable,
    admin: observable,
    popOverVisible: observable,
    currentHighlight: observable,
    zoomSignupEmail: observable,
    currentSelection: observable,
    allMomentsThisSprint: observable,
    algoliaPush: observable,
    currentInsightModalKey: observable,
    params: observable,
    showPopOver: observable,
    allInterviews: observable,
    zoomAccountId : observable,
    zoomInterviewLink: observable,
    currentInterview: observable,
    thisSprintBubble: observable,
    allSprintsBubble: observable,
    currentPile: observable,
    first_name: observable,
    last_name: observable,
    currentTranscript: observable,
    thisSprintChecked: observable,
    interviewMomentDeleteKey: observable,
    allSprintsChecked: observable,
    currentSprintForMoments: observable,
    changeCardUponDragTemp: observable,
    modalItem: observable,
    attachPersonas: observable,
    showNotesAll: observable,
    toggle: observable,
    toggle2: observable,
    interviewMomentKey: observable,
    increasePileIconSize: observable,
    navigatingFrom: observable,
    everybody: observable,
    allMomentsFilter: observable,
    everybodyAllSprints: observable,
    everybodyCleaned: observable,
    everybodyCleanedSorted: observable,
    questions: observable,
    currentUser: observable,
    currentSprint: observable,
    currentKey: observable,
    questionsCleaned: observable,
    pileData: observable, 
    togglePopOver: observable,
    personas: observable,//persona grab from db
    personasCleaned: observable,// personas for display on interviews
    personaFilter: observable, //filter for top of tabs-moments page
    cleanedMomentsWithPersonas: observable,
    sentimentFilter: observable, 
    insightFilter1: observable,
    insightFilter2:observable,
    insightFilter3:observable,
    insightFilter4: observable,
    fullFilter: observable,
    loggingIn: observable,
    insights: observable,
    insightsThisSprint: observable,
    newFilter: observable,
    needs: observable,  
    positive: observable,
    negative: observable,
    misunderstanding: observable,
    interviewMoments: observable,
    interviewMomentsCleaned: observable,
    ideas: observable,
    interesting: observable,
    allInsights: observable,
    allInsightsJustThisSprint: observable,
    allInsightsCleaned: observable,
    allMomentsAllSprints: observable, 
    allMomentsAllSprintsLoaded: observable,
    insight: observable,
    allMoments: observable,
    allInsightsWithKey: observable,
    cleanedMoments: observable,
    sprintName: observable,
    sprintTimeline: observable,
    navigation : observable,
    modalComments: observable,
    AdditionalFeedback: observable,
    momentsSupportingInsight: observable,
    questionsFeedback: observable,
    showTabsbyDefault: observable,
    slackCredentials: observable,
    slackWebhook: observable,
    // youTubePlayerObject: observable,
    videoScrubber: observable,
    // YouTubePlayerState: observable,
    //startPlayingVideo: computed,
    videoStartsAt: observable,
    stopsAt: observable,
    playButton: observable,
    bubbleData: observable,    
    infiniteScrollStart: observable,
    infiniteScrollStop: observable,
    tagColors: observable
  })


export default store;
 //needsClean =  []
  //   get everyoneSorted() {
  //     return this.everybodyCleaned.sort((a b) => { return b.timeStamp - a.timeStamp})
  // }
  //  momentForModalDirect =  {}
  //   MainfeedbackKey = ''
  //  forceInsightsMenuRender = false
  //  everybodyObject = {}
  //newArrayWithPersonas = []
  // modalState = null
  //feedbackModal = false
     
    //  segment = ''
    //  person = {}
 

  

  //These exist because I needed to snag a URL param
  //and insert it into dbRef for calls on feedback
  //patterns and pins. Need to find a better way.
  //Also there are 2 of them because I was originally
  //Going to have a new set of questions each month
  //so month 1 and 2 got their own params.
    //  urlParam1
    //  urlParam2

  //This is where the 'feedback' when being additionalFeedback
  //to a question sits until it's pushed into the new question.
  //coming in from 'adddToQuestion.js and being push to DB from
  //'interview.js' 'push moment' button
    //  sendToQuestionsDBRef
    //  sendToQuestionsFeedback
    //  sendToQuestionsYourView
    //  sendToQuestionsTimeStamp
    //  sendToQuestionsCustomerName
    //  sendToQuestionsCustomerImage
    //  sendToQuestionsPersona
    //  sendToQuestionssendToQuestionsKey


